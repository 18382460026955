import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import { inject, observer } from "mobx-react"
//import { NoSsr } from "@material-ui/core"

import landingPageData_de from "../../content/de_landingPage.json"
import landingPageData_en from "../../content/en_landingPage.json"
import seo from "../../content/seo.json"
import settings from "../../content/settings.json"
import styles from "../components/styles"
import colors from "../components/colors"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      showProductDropDown: false
    }
    //if (typeof window !== `undefined`)
      //window.navigator.language.includes("de")
        //? this.props.store.switchLanguage("de")
        //: this.props.store.switchLanguage("en")
        

  }

  toggleMenu = () => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }))
  }
  toggleDropDown = () => {
    this.setState(prevState => ({ showProductDropDown: !prevState.showProductDropDown }))
  }

  render() {
  
    const { device, language } = this.props.store
    let selectedStyles = device === "mobile" ? styles.mobile : styles.desktop
    let landingPageData =
      language === "en" ? landingPageData_en : landingPageData_de

    return (
      <div>
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
          <meta
            property="og:image"
            content={settings.defaultSocialSharingImage}
          />
          <meta property="og:title" content={seo.title} />
          <meta charSet="utf-8" />
          <html lang={language} />
          <meta name="twitter:card" content={"summary_large_image"} />
          <meta name="twitter:site" content="@liquiditeam" />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta
            name="twitter:image"
            content={
              "https://liquidi.team" + settings.defaultSocialSharingImage
            }
          />
          <meta
            name="google-site-verification"
            content="rcQMH8fQN1AcB9qtxSEBgQSLs5RJZbm-EvB4uO92DTc"
          />
        </Helmet>
        <header style={selectedStyles.header.container}>
          <div
            style={{
              margin: "auto",
              height: "100%",
              width: "1400px",
              display: "flex",
              backgroundColor: "#1D1E21",
            }}
          >
            <div style={selectedStyles.header.innerLeftContainer}>
              <Link
                to="/"
                activeStyle={selectedStyles.header.linkActive}
                style={selectedStyles.header.link}
              >
                <div>Home</div>
              </Link>
              <Link
                to="/insights"
                activeStyle={selectedStyles.header.linkActive}
                style={selectedStyles.header.link}
              >
                <div>Insights</div>
              </Link>
              <div 
                style={this.state.showProductDropDown?{display:'flex',height:'210px', zIndex:2, justifyContent:'center', flexDirection:'column', cursor:'default'}:{display:'flex', flexDirection:'column', height:'70px', zIndex:2, justifyContent:'center', cursor:'default'}} 
                onMouseLeave={() => this.toggleDropDown()}
                onMouseEnter={() => this.toggleDropDown()}
                >
              <div
                style={{
                  height:'33.3333334%',
                  textDecoration:'none',
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 10,
                  backgroundColor: "#1D1E21",
                  color: "white",
                  border: "none",
                  outline: "none",
                }}
              >
                <div style={{}}>{"Products"}</div>
              </div>
              {this.state.showProductDropDown && <Link
                to={'/lt-fan-platform'}
                activeStyle={{
                  height:'33.3333334%',
                  textDecoration:'none',
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 10,
                  backgroundColor: "#2B2E34",
                  color: "lightgrey",
                  border: "none",
                  outline: "none",
                }}
                style={{height:'33.3333334%',
                  textDecoration:'none',
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 10,
                  backgroundColor: "#1D1E21",
                  color: "white",
                  border: "none",
                  outline: "none",}}
              >
                <div style={{}}>{"LT Fan Platform"}</div>
              </Link>}
              {this.state.showProductDropDown && <Link
                to='/lt-sports-investment-platform'
                activeStyle={{height:'33.3333334%',
                  textDecoration:'none',
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 10,
                  backgroundColor: "#2B2E34",
                  color: "lightgrey",
                  border: "none",
                  outline: "none",}}
                style={{
                  height:'33.3333334%',
                  textDecoration:'none',
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 10,
                  backgroundColor: "#1D1E21",
                  color: "white",
                  border: "none",
                  outline: "none",
                }}
              >
                <div style={{}}>{"LT Sports Invest"}</div>
              </Link>}
              </div>
            </div>

            <BurgerMenuButton
              showMenu={this.state.showMenu}
              toggleMenu={() => this.toggleMenu()}
              selectedStyles={selectedStyles}
            />

          <div style={selectedStyles.header.innerMiddleContainer}>
            <Link style={selectedStyles.header.linkImgContainer} to="/">
              <img
                style={selectedStyles.header.img}
                src={landingPageData.headerLogo}
                alt="liquiditeam logo"
              />
            </Link>
          </div>
          <div style={selectedStyles.header.dummyRightContainer}/>
          <div style={selectedStyles.header.innerRightContainer}>
          <Link
              to="/about"
              activeStyle={selectedStyles.header.linkActive}
              style={selectedStyles.header.link}
            >
              <div>About</div>
            </Link>
            <Link
              to="/careers"
              activeStyle={selectedStyles.header.linkActive}
              style={selectedStyles.header.link}
            >
              <div>Careers</div>
            </Link>
            <Link
              to="/contact"
              activeStyle={selectedStyles.header.linkActive}
              style={selectedStyles.header.link}
            >
              {this.props.store.language === "en" ? "Contact" : "Kontakt"}
            </Link>
            <button
              onClick={() => {
                this.props.store.switchLanguage("en")
              }}
              style={
                this.props.store.language === "en"
                  ? selectedStyles.header.buttonActive
                  : selectedStyles.header.buttonInactive
              }
            >
              EN
            </button>
            <button
              onClick={() => {
                this.props.store.switchLanguage("de")
              }}
              style={
                language === "de"
                  ? selectedStyles.header.buttonActive
                  : selectedStyles.header.buttonInactive
              }
            >
              DE
            </button>
          </div>
          
        </div>
        </header>
        {this.state.showMenu === true ? (
          <div style={selectedStyles.header.menuContainer}>
            <Link
              to="/"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuButtonActive}
              style={selectedStyles.header.menuButtonInactive}
            >
              <div>Home</div>
            </Link>
            <Link
              to="/insights"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuButtonActive}
              style={selectedStyles.header.menuButtonInactive}
            >
              <div>Insights</div>
            </Link>
            <div
              //to="/insights"
              //onClick={() => this.toggleMenu()}
              activeStyle={[selectedStyles.header.menuButtonActive]}
              style={selectedStyles.header.menuButtonInactive}
            >
              <div >{this.props.store.language === "en" ? "Products" : "Produkte"}</div>
            </div>
            <Link
              to="/lt-fan-platform"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuSubbuttonActive}
              style={selectedStyles.header.menuSubbuttonInactive}
            >
              <div>LT Fan Plattform</div>
            </Link>
            <Link
              to="/lt-sports-investment-platform"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuSubbuttonActive}
              style={selectedStyles.header.menuSubbuttonInactive}
            >
              <div>LT Sports Invest</div>
            </Link>
            <Link
              to="/careers"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuButtonActive}
              style={selectedStyles.header.menuButtonInactive}
            >
              <div>Careers</div>
            </Link>
            <Link
              to="/about"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuButtonActive}
              style={selectedStyles.header.menuButtonInactive}
            >
              <div>About</div>
            </Link>
            <Link
              to="/contact"
              onClick={() => this.toggleMenu()}
              activeStyle={selectedStyles.header.menuButtonActive}
              style={selectedStyles.header.menuButtonInactive}
            >
              <div>
                {this.props.store.language === "en" ? "Contact" : "Kontakt"}
              </div>
            </Link>

            <div
              style={{
                height: "11.112%",
                borderTopStyle: "solid",
                borderColor: colors.darkblue,
                borderTopWidth: "1px",
              }}
            >
              <div
                onClick={() => {
                  this.props.store.switchLanguage("en")
                  this.toggleMenu()
                }}
                style={
                  this.props.store.language === "en"
                    ? selectedStyles.header.languageButtonActive
                    : selectedStyles.header.languageButtonInactive
                }
              >
                English
              </div>
              <div
                onClick={() => {
                  this.props.store.switchLanguage("de")
                  this.toggleMenu()
                }}
                style={
                  this.props.store.language === "de"
                    ? selectedStyles.header.languageButtonActive
                    : selectedStyles.header.languageButtonInactive
                }
              >
                German
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
const BurgerMenuButton = props => {
  let { selectedStyles } = props
  return (
    <div
      style={selectedStyles.header.burgerContainer}
      onClick={() => props.toggleMenu()}
    >
      <div
        style={
          props.showMenu
            ? selectedStyles.header.burgerActive
            : selectedStyles.header.burger
        }
      ></div>
      <div
        style={
          props.showMenu
            ? selectedStyles.header.burgerActive
            : selectedStyles.header.burger
        }
      ></div>
      <div
        style={
          props.showMenu
            ? selectedStyles.header.burgerActive
            : selectedStyles.header.burger
        }
      ></div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default inject("store")(observer(Header))

/*const LanguageButton = inject("store")(
  observer(props => {
    return (
      <NoSsr>
        <div
          onClick={() => {
            props.store.switchLanguage(props.text === "EN" ? "en" : "de")
          }}
          style={
            (props.store.language === "en" && props.text === "EN") ||
            (props.store.language === "de" && props.text === "DE")
              ? props.selectedStyles.header.buttonActive
              : props.selectedStyles.header.buttonInactive
          }
        >
          {props.text}
        </div>
      </NoSsr>
    )
  })
)
*/
