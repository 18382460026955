const colors = {
    darkblue: '#2B2E34',
    darkgrey:'#1D1E21' ,
    grey:'#5D6168',
    lightgrey:'#ECF0F1',
    black:'#000000',
    gold: '#B49423', 
    white:'white',
    textLightgrey: '#A8B8C7',
    textDarkgrey: '#4C5159',
    greyBackground: '#E5E5E5',
    teamTags: '#728190'

}
export default colors