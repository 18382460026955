import colors from "./colors"

const styles = {
  mobile: {
    
    index: {
      section1: {
        polygonTextContainer: {
          paddingLeft: "10vw",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          paddingBottom: "20vh",
        },
  
      },
      upperSection: {
        container: {
          height: "55vh",
          backgroundColor: colors.darkblue,
          //display: "flex",
          zIndex: -6,
        },
        polygon: {
          height: "110%",
          backgroundColor: colors.black,
          width: "100vw",
          //marginRight: "-50vw",
          zIndex: 1,
          WebkitClipPath: "polygon(0% 0%, 100% 0%,100% 30%, 30% 100%, 0% 100%)",
          clipPath: "polygon(0% 0%, 100% 0%,100% 30%, 30% 100%, 0% 100%)",
          display: "flex",
        },
        polygonTextContainer: {
          paddingLeft: "10vw",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          paddingBottom: "20vh",
        },
        heading: {
          paddingRight: "20vw",
          fontSize: "7vw",
          fontFamily: "Source Sans Pro",
        },
        text: {
          paddingBottom:'50px',
          paddingRight: "30vw",
          fontSize: "4.5vw",
          color: colors.textLightgrey,
        },
        cta: {
          border: "none",
          WebkitAppearance:'none',
          MosAppearance: 'none',
          appearance: 'none',
          cursor: "pointer",
          backgroundColor: colors.gold,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          padding: "10px 0px",
          textDecoration:'none',
          width: "200px",
          maxWidth:'50%',
          color: colors.white,
        },
        img: {
          position: "absolute",
          height: "55vh",
          width: "auto",
          objectFit:'cover'
        },
        goldFeature: {
          marginTop: "2.5vh",
          backgroundColor: colors.gold,
          width: 100,
          height: 3,
          zIndex: 10,
          position: "absolute",
          left: "22vw",
        },
        goldFeatureContainer: {
          width: "100vw",
        },
      },
      middleSection: {
        container: {
          //height: "60vh",
          flexDirection: "column-reverse",
          alignItems: "center",
          backgroundColor: colors.darkblue,
          display: "flex",
          paddingTop: "10vh",
          paddingBottom:'5vh'
        },
        imgContainer: {
          width: "80vw",
          height:'100%',
          float: "left",
          //display: "flex",
        },
        vidContainer: {
            width: '80vw',
            height: '45vw',
            /* display: flex; */
            /* align-items: center; */
        
          //display: "flex",
        },
        img: {
          maxHeight: "60%",
          objectFit: "contain",
          margin: "auto",
        },
        textContainer: {
          width: "95vw",
          float: "left",
        },
        innerTextContainer: {
          margin: "0vw 5vw 5vw 5vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        heading: {
          fontSize: "4.5vw",
          color: colors.gold,
        },
        subHeading: {
          fontSize: "7vw",
          marginBottom: "1rem",
        },
        text: {
          color: colors.textLightgrey,
        },
      },
      section4: {
        container: {
          //height: "60vh",
          flexDirection: "column-reverse",
          alignItems: "center",
          //backgroundColor: colors.darkblue,
          display: "flex",
          paddingTop: "10vh",
          paddingBottom:'5vh',
        },
        imgContainer: {
          width: "80vw",
          //height:'100%',
          float: "left",
          //display: "flex",
        },
        img: {
          maxHeight: "60%",
          objectFit: "contain",
          margin: "auto",
        },
        textContainer: {
          width: "95vw",
          float: "left",
        },
        innerTextContainer: {
          margin: "0vw 5vw 5vw 5vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        heading: {
          fontSize: "4.5vw",
          color: colors.gold,
        },
        subHeading: {
          fontSize: "7vw",
          marginBottom: "1rem",
          color: colors.black
        },
        text: {
          color: colors.textDarkgrey,
        },
        buttonContainer:{
          margin:'0vh 0vh 5vh 0vh',
          display:'flex',
          justifyContent:'center',
          width:'100%'
        }
      },
      lowerSection: {
        upperBackground: {
          height: "9.95vw",
          backgroundColor: colors.lightgrey,
          zIndex: "-1",
          position: "relative",
        },
        lowerBackground: {
          height: "10vw",
          marginTop:'-10vw',
          backgroundColor: colors.lightgrey,
          zIndex: "-1",
          position: "relative",
        },/*
        upperBackground: {
          height: "70vh",
          backgroundColor: colors.darkblue,
          zIndex: "-1",
          position: "relative",
        },
        lowerBackground: {
          height: "70vh",
          backgroundColor: colors.grey,
          zIndex: "-1",
          position: "relative",
        },*/
        container: {
          height: "100vh",
          marginTop: "-100vh",
          backgroundColor: colors.lightgrey,
          WebkitClipPath: "polygon(0% 100%,5% 90% ,95% 90%, 100% 80%, 100% 0%, 95% 10%, 5% 10%, 0% 20%)",
          clipPath: "polygon(0% 100%,5% 90% ,95% 90%, 100% 80%, 100% 0%, 95% 10%, 5% 10%, 0% 20%)",
          display: "flex",
        },
        imgContainer: {
          width: "80vw",
        },
        vidContainer: {
          width: '80vw',
          height: '45vw',
          /* display: flex; */
          /* align-items: center; */
      
        //display: "flex",
      },
        img: {
          maxHeight: "60%",
          objectFit: "contain",
          margin: "auto",
        },
        textContainer: {
          width: "95vw",
        },
        innerTextContainer: {
          margin: "0vw 5vw 0vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: colors.black,
        },
        heading: {
          fontSize: "4.5vw",
          color: colors.gold,
        },
        subHeading: {
          fontSize: "7vw",
        },
        text: {
          marginBottom:'1.45rem',
          fontSize: "4.5vw",
          color: colors.textDarkgrey,
        },
      },
      teamSection: {
        container: {
          padding: "0vw 5vw",
          height: "130vh",
          backgroundColor: colors.grey,
        },
        memberContainer: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          //justifyContent: "space-between",
        },
        upperBackground: { height: "5vh", backgroundColor: colors.grey },
        lowerBackground: { height: "10vh", backgroundColor: colors.darkblue },
        contactButtonContainer: {
          height: "15vh",
          marginTop: "-15vh",
          backgroundColor: colors.darkgrey,
          WebkitClipPath: "polygon(10% 100%, 100% 100%, 100% 0%, 40% 0%)",
          clipPath: "polygon(10% 100%, 100% 100%, 100% 0%, 40% 0%)",
        },
        contactButton: {
          width: "65%",
          height: "100%",
          cursor: "pointer",
          float: "right",
          margin: 0,
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        },
        buttonText: { margin: 0, fontSize: "6vw" },
        buttonLogo: { margin: "3vw", height: "40px", width: "40px" },
        heading: {
          padding: "0vw 0vw 0vw 5vw",
          fontSize: "1.5625rem",
        },
        goldFeature: {
          position: "absolute",
          backgroundColor: colors.gold,
          width: 60,
          height: 3,
          marginTop: "-12.5vh",
          left: "27%",
          zIndex: 10,
        },
      },
      newsletterSection: {
        container: {
          backgroundColor: colors.lightgrey,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: '0px 20px'
        },
        innerContainer: {
          display: "flex",
          
          flexDirection: "column",
          
          margin: "50px 0px 0px",
        },
        textContainer: { paddingRight: "5vw", marginBottom: "25px" },
        heading: {
          color: colors.black,
          fontSize: "7vw",
          letterSpacing: "0.05em",
        },
        text: {
          color: colors.black,
          fontSize: "0.9375rem",
          marginBottom: 0,
          width: "70%",
        },
        input: {
          margin: "10px 0px",
          border: "none",
          padding: "10px 5px",
          width: "100%",
        },
        button: {
          color: colors.white,
          border: "none",
          cursor: "pointer",
          backgroundColor: colors.gold,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          padding: "10px 0px",
          width: "100%",
          margin: "5px 0px",
        },

        gdprTextContainer:{color:colors.textLightgrey, paddingTop:'1.45rem'}
      },
    },
    header: {
      container: {
        flex: "1",
        height: "7vh",
        backgroundColor: "#1D1E21",
        display: "flex",
        justifyContent: "space-between",
      },
      innerLeftContainer: { flex: 1, display: "none" },
      linkActive: { backgroundColor: "#2B2E34", color: "lightgrey" },
      link: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
      },
      contactButton: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
      },
      innerMiddleContainer: { flex: 2, display: "flex" },
      linkImgContainer: { display: "flex", alignSelf: "center" },
      img: { alignSelf: "center", maxHeight: "7vh" },
      innerRightContainer: {
        flex: 1,
        display: "none",
        justifyContent: "flex-end",
      },
      dummyRightContainer:{
        flex:1
      },
      buttonActive: {
        cursor: "pointer",
        backgroundColor: "#2B2E34",
        //display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        color: "lightgrey",
        border: "none",
        outline: "none",
        display: "none",
        
      },
      buttonInactive: {
        cursor: "pointer",
        //display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "#1D1E21",
        color: "white",
        border: "none",
        outline: "none",
        display: "none",
        
      },
      menuContainer:{width:'100%', height:'75vh', backgroundColor:colors.darkgrey},
      menuButtonInactive: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        paddingLeft:'30px',
        paddingRight: 10,
        color: "white",
        height: "11.11112%",
        backgroundColor:'transparent', outline:'none', margin:0,  border:'none'
      },
      menuSubbuttonInactive: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        paddingLeft:'30px',
        paddingRight: 10,
        color: "white",
        height: "11.11112%",
        backgroundColor:'transparent', outline:'none', margin:0,  border:'none',
        marginLeft:'45px'
      },
      menuButtonActive: { 
        paddingLeft:'30px',
        backgroundColor: "#2B2E34", 
        color: "lightgrey",
        height: "11.11112%",
      },
      menuSubbuttonActive: { 
        paddingLeft:'30px',
        backgroundColor: "#2B2E34", 
        color: "lightgrey",
        height: "11.11112%",
        marginLeft: '45px'
      },
      languageButtonInactive:{height: '100%', width:'50%', float:'left', display:'flex', justifyContent:'center', alignItems:'center', color: colors.textLightgrey, backgroundColor:'transparent', outline:'none', margin:0, padding:0, border:'none'},
      languageButtonActive:{height: '100%', width:'50%', float:'left', display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:colors.darkblue, color: colors.white, outline:'none', margin:0, padding:0, border:'none'},
      burgerContainer: {
        display: "inline-block",
        cursor: "pointer",
        flex: 1,
        margin: "auto",
        paddingLeft: "10px",
      },
      burger: {
        width: "35px",
        height: "5px",
        borderRadius: "5px",
        backgroundColor: colors.textLightgrey,
        margin: "6px 0",
      },
      burgerActive: {
        width: "35px",
        height: "5px",
        borderRadius: "5px",
        backgroundColor: colors.gold,
        margin: "6px 0",
      },
    },
    layout: {
      container: {
        paddingTop: 0,

      },
      footer: {
        container: {
          backgroundColor: colors.darkgrey,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "20px 0px",
          height: "25vh",
        },
        imgStyles: {
          width: "80vw",
        },
        linkContainer: {
          display: "inline",
        },
        linkStyles: {
          color: colors.white,
          textDecoration: "none",
        },
        buttonStyles: {
          cursor: "pointer",
          margin: 0,
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          color: "white",
        },
        footerLine: {
          backgroundColor: colors.gold,
          height: "3vh",
        },
      },
    },
    teamMember: {
      container: {
        width: "90vw",
        height: "25vh",
        marginBottom:'5vh',

      },

      innerContainer: {
        backgroundColor: colors.lightgrey,
        height: "25vh",
        width: "100%",
      },
      imgContainer: {
        width: "45%",
        height: '25vh',
        float: "left",
      },
      infoContainer: {
        width: "55%",
        height: "100%",
        float: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      },
      img: {
        width: "100%",
        minHeight: "100%",
        maxHeight:'25vh',
        objectFit: "cover",
      },
      name: {
        fontSize: "1.25rem",
        textAlign: "center",
        color: colors.black,
        marginBottom: 0,
      },
      position: {
        paddingBottom:'20px',
        textAlign: "center",
        color: colors.textDarkgrey,
        fontSize: "0.9375rem",
        marginBottom: 0,
      },
    },
  },

  desktop: {
    index: {
      section1:{
        polygonTextContainer: {
          margin:'100px 0px',
          paddingLeft: "10%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        },

      },
      upperSection: {
        container: {
          height: "55vh",
          backgroundColor: colors.darkblue,
          //display: "flex",
          zIndex: -6,
        },
        polygon: {
          height: "105%",
          backgroundColor: colors.black,
          width: "75%",
          //marginRight: "-50vw",
          zIndex: 1,
          WebkitClipPath: "polygon(0% 0%, 100% 0%, 66% 100%, 0% 100%)",
          clipPath: "polygon(0% 0%, 100% 0%, 66% 100%, 0% 100%)",
          display: "flex",
        },
        polygonTextContainer: {
          paddingLeft: "10%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        },
        heading: {
          paddingRight: "20%",
          fontSize:'6vh',
          fontFamily: "Source Sans Pro",
        },
        text: {
          paddingBottom:'50px',
          paddingRight: "30%",
          fontSize: "1.5rem",
          color: colors.textLightgrey,
        },
        cta: {
          marginBottom:'50px',
          border: "none",
          textDecoration:'none',
          cursor: "pointer",
          backgroundColor: colors.gold,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          padding: "10px 0px",
          width: "200px",
          color: colors.white,
          textDecoration:'none'
        },
        img: {
          objectFit:'cover',
          position: "absolute",
          height: "55vh",
          width: "auto",
          right: 0,
        },
        goldFeature: {
          marginTop: "1.3vh",
          backgroundColor: colors.gold,
          width: 150,
          height: 3,
          zIndex: 10,
        },
        goldFeatureContainer: {
          backgroundColor: colors.darkblue,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        },
      },
      middleSection: {
        cta: {
          //marginBottom:'50px',
          border: "none",
          textDecoration:'none',
          cursor: "pointer",
          backgroundColor: colors.gold,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          padding: "10px 0px",
          width: "200px",
          color: colors.white,
          textDecoration:'none'
        },
        container: {
          //height: "60vh",
          backgroundColor: colors.darkblue,
          display: "flex",
          paddingTop: "5vh",
        },
        imgContainer: {
          width: "40vw",
          float: "left",
          display: "flex",
        },
        vidContainer: {
          width: "40vw",
          float: "left",
          display: "flex",
          alignItems:'center',
          marginLeft:'5vw'
        },
        img: {
          maxHeight: "450px",
          objectFit: "contain",
          margin: "auto",
        },
        textContainer: {
          maxWidth: "60%",
          float: "left",
        },
        innerTextContainer: {
          margin: "0vh 5vh 5vh 5vh",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        heading: {
          color: colors.gold,
        },
        subHeading: {
          marginBottom: "1rem",
        },
        text: {
          color: colors.textLightgrey,
        },
      },
      lowerSection: {
        upperBackground: {
          height: "9.95vw",
          backgroundColor: colors.lightgrey,
          zIndex: "-1",
          position: "relative",
        },
        text: {
          marginBottom:'1.45rem',
          //fontSize: "4.5vw",
          //color: colors.textDarkgrey,
        },
        lowerBackground: {
          height: "10vw",
          marginTop:'-10vw',
          backgroundColor: colors.lightgrey,
          zIndex: "-1",
          position: "relative",
        },
        imgContainer: {
          width: "40vw",
          //height:'40vw',
          float: "left",
          display: "flex",
          //marginBottom:'5%'
        },
        vidContainer: {
          width: "40vw",
          height:'35vw',
          float: "left",
          display: "flex",
          alignItems:'center',
          marginRight: '5vw'
        },
        
        img: {
          maxHeight: "450px",
          objectFit: "contain",
          margin: "auto",
        },
        textContainer: {
          width: "60vw",
          float: "left",
        },
        innerTextContainer: {
          margin: "5vh 10% 0vh" ,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: colors.black,
        },
        heading: {
          color: colors.gold,
        },
        subHeading: {
          color: colors.black,
        },
      },
      section4: {
        container: {
          //height: "60vh",
          //backgroundColor: colors.darkblue,
          display: "flex",
          //paddingTop: "5vh",
        },
        imgContainer: {
          width: "40vw",
          float: "left",
          display: "flex",
        },
        img: {
          maxHeight: "450px",
          objectFit: "contain",
          margin: "auto",
        },
        textContainer: {
          maxWidth: "60%",
          float: "left",
        },
        innerTextContainer: {
          margin: "0vh 5vh 5vh 5vh",
          //height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        heading: {
          color: colors.gold,
        },
        subHeading: {
          color: colors.black,
          marginBottom: "1rem",
        },
        text: {
          color: colors.textDarkgrey,
        },
        buttonContainer:{
          margin:'5vh'
        }
      },
      teamSection: {
        container: {
          padding: "0vw 5%",
          //height: "65vh",
          backgroundColor: colors.grey,
        },
        memberContainer: {
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingBottom:'5vh'
        },
        upperBackground: { height: "5vh", backgroundColor: colors.grey },
        lowerBackground: { height: "15vh", backgroundColor: colors.darkblue },
        contactButtonContainer: {
          height: "20vh",
          marginTop: "-20vh",
          backgroundColor: colors.darkgrey,
          WebkitClipPath: "polygon(60% 100%, 100% 100%, 101% 0%, 70% 0%)",
          clipPath: "polygon(60% 100%, 100% 100%, 101% 0%, 70% 0%)",
        },
        contactButton: {
          width: "30%",
          height: "100%",
          cursor: "pointer",
          float: "right",
          margin: 0,
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        },
        buttonText: { margin: 0, fontSize: "1.8rem" },
        buttonLogo: { margin: "3vw", height: "50px", width: "50px" },
        heading: {
          padding: "0vw 0vw 0vw 5vw",
          fontSize: "1.5625rem",
        },
        
        goldFeature: {
          position: "absolute",
          backgroundColor: colors.gold,
          width: 150,
          height: 3,
          marginTop: "-17.5vh",
          marginLeft: '-75px',
          left: "69%",
          zIndex: 10,
        },
      },
      newsletterSection: {
        container: {
          padding: '7vh 3vh',
          backgroundColor: colors.lightgrey,
          display: "flex",
          flexDirection:'column',
          alignItems: "center",
          justifyContent: "center",
        },
        innerContainer: { display: "flex", alignItems: "flex-end" },
        textContainer: { paddingRight: "5vw" },
        heading: { color: colors.black, marginBottom: 0, fontSize: "2rem" },
        text: { color: colors.black, fontSize: "22", marginBottom: 0 },
        input: {
          margin: "5px",
          border: "none",
          padding: "10px 5px",
          width: "25vw",
        },
        button: {
          color: colors.white,
          border: "none",
          cursor: "pointer",
          backgroundColor: colors.gold,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          padding: "10px 0px",
          width: "250px",
          margin: "5px 0px",
        },
        gdprTextContainer:{color:colors.textLightgrey, paddingTop:'1.45rem'}
      },
    },
    header: {
      container: {
        flex: "1",
        height: "70px",
        backgroundColor: "#1D1E21",
        // backgroundColor: "#1D1E21", for not full width header
        display: "flex",
      },
      innerLeftContainer: { flex: 1, display: "flex" },
      linkActive: { backgroundColor: "#2B2E34", color: "lightgrey" },
      link: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        color: "white",
      },
      innerMiddleContainer: { flex: 1, display: "flex" },
      linkImgContainer: { display: "flex", alignSelf: "center" },
      img: { alignSelf: "center", maxHeight: "5vh" },
      innerRightContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
      },
      buttonActive: {
        cursor: "pointer",
        backgroundColor: "#2B2E34",
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        color: "lightgrey",
        border: "none",
        outline: "none",
      },
      buttonInactive: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "#1D1E21",
        color: "white",
        border: "none",
        outline: "none",
      },
      contactButton: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 10,
        backgroundColor: "#1D1E21",
        color: "white",
        border: "none",
        outline: "none",
      },
      blogButton: {
        textDecoration:'none',
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 10,
        backgroundColor: "#1D1E21",
        color: "white",
        border: "none",
        outline: "none",
      },
      burgerContainer: { display: "none" },
      burger: {
        width: "35px",
        height: "5px",
        backgroundColor: colors.textLightgrey,
        margin: "6px 0",
      },
    },
    layout: {
      container: {
        maxWidth: 1400,
        margin:'auto',
        paddingTop: 0,
      },
      footer: {
        container: {
          backgroundColor: colors.darkgrey,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "20px 0px",
          height: "25vh",
        },
        imgStyles: {
          width: "30vw",
        },
        linkContainer: {
          display: "inline",
        },
        linkStyles: {
          color: colors.white,
          textDecoration: "none",
        },
        buttonStyles: {
          cursor: "pointer",
          margin: 0,
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          color: "white",
        },
        footerLine: {
          backgroundColor: colors.gold,
          height: "3vh",
        },
      },
    },
    teamMember: {
      container: {
        flex: 1,
        width:'40%',
        maxWidth: '325px',
        //maxHeight: "60vh",
        padding:'2%'
      },

      innerContainer: {
        backgroundColor: colors.lightgrey,
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        padding:'0px 0px 15px 0px'
      },
      img: {
        //maxHeight:'100%',
        width: "100%",
        //height: "13vw",
        objectFit: "cover",
      },
      name: {
        textAlign: "center",
        color: colors.black,
        paddingTop:'20px',
        fontSize:'25px'
      },
      position: {
        paddingBottom:'20px',
        textAlign: "center",
        color: colors.textDarkgrey,
      },
    },
  },
}
export default styles
