import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { inject, observer } from "mobx-react"

import Header from "./header"
import "./layout.css"
import styles from "../components/styles"
import colors from "../components/colors"
import landingPageData_de from "../../content/de_landingPage.json"
import landingPageData_en from "../../content/en_landingPage.json"
import settings from "../../content/settings.json"
import CookieConsent from "react-cookie-consent"
import ReactMarkdown from "react-markdown/with-html"

const consentText_de =
  'Wir übermitteln Daten an Drittanbieter um unser Webangebot zu verbessern und zu finanzieren. In diesem Zusammenhang können Daten außerhalb des EWR verarbeitet werden und auch Nutzungsprofile gebildet und mit Daten von anderen Webseiten angereichert werden.<br/>Ihre personenbezogenen Daten (IP-Adressen o.ä.) werden verwendet, um Informationen auf Ihrem Gerät zu speichern und /oder darauf zugreifen (z. B. Cookies, Geräte-Kennungen oder andere Informationen), personalisierte Anzeigen und Inhalte anzuzeigen, Anzeigen- und Inhaltsmessungen vorzunehmen sowie Erkenntnisse über Zielgruppen und Produktentwicklungen zu gewinnen sowie die Nutzung unserer Angebote zu analysieren und dafür Marketing machen und den Erfolg messen zu können.<br/>Indem Sie "Akzeptieren" klicken, stimmen Sie (jederzeit widerruflich) diesen Datenverarbeitungen freiwillig zu. Dies umfasst zeitlich begrenzt auch Ihre Einwilligung zur Datenverarbeitung außerhalb des EWR wie in den USA (Art. 49 Abs. 1 lit. a) DS-GVO), wo das hohe europäische Datenschutzniveau nicht besteht, so dass die Daten dem Zugriff durch Behörden zu Kontroll- und Überwachungszwecken unterliegen können, gegen die weder wirksame Rechtsbehelfe noch Betroffenenrechte durchsetzbar sein können. Unter Einstellungen oder über die Datenschutzerklärung können Sie Ihre Einstellungen jederzeit ändern oder Datenverarbeitungen ablehnen. Diese Einwilligung ist freiwillig und kann jederzeit widerrufen werden. Weitere Informationen finden Sie in der Datenschutzerklärung.'
const consentText_en =
  'We transmit data to third-party providers in order to improve and finance our web offering. In this context, data may be processed outside the EEA and usage profiles may also be formed and enriched with data from other websites.<br/>Your personal data (IP addresses or similar) is used to store and/or access information on your device (e.g. cookies, device identifiers or other information), to display personalized ads and content, to measure ads and content, and to gain insights into target groups and product developments, as well as to analyze the use of our offers and to be able to do marketing and measure success.<br/>By clicking "Accept", you voluntarily consent (revocable at any time) to these data processing activities. This also includes, for a limited period of time, your consent to data processing outside the EEA, such as in the USA (Art. 49 (1) a) DS-GVO), where the high European level of data protection does not exist, so that the data may be subject to access by authorities for control and monitoring purposes, against which neither effective legal remedies nor data subject rights may be enforceable. Under Settings or via the Privacy Policy, you can change your settings or refuse data processing at any time. This consent is voluntary and can be revoked at any time. You can find more information in the privacy policy.'

const CookieBannerChild = props => {
  if (props.device === 'mobile'){
  return (
    <div style={{ lineHeight: 0.5 }}>
      <ReactMarkdown escapeHtml={false}>
        {'<font size="1">' + props.source + "</font>"}
      </ReactMarkdown>
    </div>
  )}
  if (props.device === 'desktop'){
    return (
      <div style={{ lineHeight: 1 }}>
        <ReactMarkdown escapeHtml={false}>
          {'<font size="3">' + props.source + "</font>"}
        </ReactMarkdown>
      </div>
    )}
  }

class Layout extends React.Component {
  /*constructor (props) {
    super(props)
    //if (typeof window !== `undefined`) window.navigator.language.includes("de")?this.props.store.switchLanguage('de'):this.props.store.switchLanguage('en')
  }*/

  componentDidMount() {
    this.handleResize()
    if (typeof window !== "undefined")
      window.addEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    if (
      document.documentElement.clientWidth > 699 &&
      this.props.store.device !== "desktop"
    ) {
      this.props.store.setDevice("desktop")
    }
    if (
      document.documentElement.clientWidth < 900 &&
      this.props.store.device !== "mobile"
    ) {
      this.props.store.setDevice("mobile")
    }
  }

  render() {
    let landingPageData =
      this.props.store.language === "en"
        ? landingPageData_en
        : landingPageData_de

    return (
      <>
        <CookieConsent
          location="bottom"
          buttonText={
            this.props.store.language === "en" ? "Accept" : "Akzeptieren"
          }
          style={{ background: colors.darkgrey }}
          buttonStyle={{
            backgroundColor: colors.gold,
            color: colors.white,
            fontWeight: "bold",
          }}
          declineButtonStyle={{
            backgroundColor: colors.grey,
            color: colors.white,
            fontWeight: "bold",
          }}
          declineButtonText={
            this.props.store.language === "en" ? "Decline" : "Ablehnen"
          }
          enableDeclineButton
          //cookieName="gatsby-gdpr-google-analytics"
          cookieName="gatsby-plugin-google-gtag"
          debug={false}
        >
          <CookieBannerChild
          device={this.props.store.device}
            source={
              this.props.store.language === "en"
                ? consentText_en
                : consentText_de
            }
          />
        </CookieConsent>
        <div style={{ backgroundColor: colors.black, minHeight: "100%" }}>
          <Header
            language={this.props.store.language}
            device={this.props.store.device}
          />
          <div style={styles.desktop.layout.container}>
            <main>{this.props.children}</main>
          </div>
          <footer style={styles.desktop.layout.footer.container}>
            <div>
              <img
                src={landingPageData.headerLogo}
                alt="liquidteam logo"
                style={
                  this.props.store.device === "desktop"
                    ? styles.desktop.layout.footer.imgStyles
                    : styles.mobile.layout.footer.imgStyles
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                margin: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              An
              <img
                src={landingPageData_en.secondaryLogo}
                alt="liquidteam logo"
                style={{
                  margin: "0px 20px",
                  height:
                    this.props.store.device === "desktop" ? "20px" : "15px",
                  objectFit: "contain",
                }}
              />
              Venture
            </div>
            <div style={styles.desktop.layout.footer.linkContainer}>
              <Link
                to="/impressum"
                style={styles.desktop.layout.footer.linkStyles}
                state={{ lang: this.props.store.language }}
              >
                Impressum
              </Link>{" "}
              {" | "}
              <Link
                to="/privacy"
                style={styles.desktop.layout.footer.linkStyles}
                state={{ lang: this.props.store.language }}
              >
                {" "}
                {this.props.store.language === "en"
                  ? " Privacy Policy"
                  : " Datenschutz"}
              </Link>{" "}
              {" | "}
              <button
                onClick={() =>
                  typeof window !== "undefined"
                    ? (window.location.href = `mailto:${settings.email}`)
                    : null
                }
                style={styles.desktop.layout.footer.buttonStyles}
              >
                {" "}
                {this.props.store.language === "en" ? " Contact" : " Kontakt"}
              </button>
            </div>
          </footer>
          <div style={styles.desktop.layout.footer.footerLine}></div>
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default inject("store")(observer(Layout))
